<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.users')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn
          text
          small
          color="red"
          :disabled="!selected"
          @click="forceLogout(selected.uuid)"
        >
          <v-icon>mdi-logout-variant</v-icon>
          <span class="text--secondary">{{ $t("login.logout") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="blue"
          :disabled="!selected"
          @click="startTrack(selected.uuid)"
        >
          <v-icon>mdi-map-marker-circle</v-icon>
          <span class="text--secondary">{{ $t("user.track") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          color="green"
          @click="$$router.push({ name: editRoute, params: { userId: 'new', back: { name: $route.name } } })"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected || selected.typeName === 'CUSTOMER'"
          v-if="type !== 'CUSTOMER' && !type.includes('CUSTOMER')"
          @click="editRoles()"
        >
          <v-icon>mdi-key</v-icon>
          <span>{{ $t("user.roles") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          v-if="!!type && (type === 'CUSTOMER' || type.includes('CUSTOMER'))"
          :disabled="!selected"
          @click="$$router.push({ name: 'admin.customer.region', params: { regionId: selected.id, back: { name: $route.name } }})"
          >
          <v-icon>mdi-map-marker-circle</v-icon>
          <span>{{ $t("app.route.region") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected || selected.typeName === 'CUSTOMER'"
          v-if="type !== 'CUSTOMER' && !type.includes('CUSTOMER')"
          @click="editStores()"
        >
          <v-icon>mdi-key</v-icon>
          <span>{{ $t("user.assignedStores") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected"
          @click="editUser()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="red"
          v-if="$hasRole('SUPER-ADMIN') || $hasRole('ADMIN')"
          :disabled="!selected"
          @click="confirmDelete()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(124)">
        <v-data-table
          dense
          :headers="headers"
          :items="getUsersOfTypes"
          item-key="id"
          :search="search"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <!-- <template #header.id="{ header }">{{ $t(header.text) }}</template> -->
          <template #header.username="{ header }">
            <span class="ml-9">{{ header.text }}</span>
          </template>
          <!-- <template #header.name="{ header }">{{ $t(header.text) }}</template>
          <template #header.email="{ header }">{{ $t(header.text) }}</template>
          <template #header.roles="{ header }">{{ $t(header.text) }}</template> -->

          <template #item.username="{ item }">
            <v-avatar size="32" class="my-1 mr-1">
              <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
              <v-icon v-else large color="primary">{{
                userInitials(item.username)
              }}</v-icon>
            </v-avatar>
            <v-icon
              :color="isOnline(item.uuid) ? 'green' : 'grey lighten-2'"
            >
              {{
                isOnline(item.uuid) ? "mdi-check-circle" : "mdi-alert-circle-outline"
              }}
            </v-icon>
            <span
              :class="
                !item.enabled ? 'text-decoration-line-through red--text' : ''
              "
              >{{ item.username }}</span
            >
            <v-icon v-if="!item.enabled" color="red" small
              >mdi-account-cancel</v-icon
            >
          </template>
          <template #item.roles="{ item }">
            <v-chip
              v-for="role in item.roles"
              :key="role"
              :color="
                role === 'ADMIN' || role === 'SUPER-ADMIN'
                  ? 'red'
                  : role === 'CUSTOMER'
                  ? 'green'
                  : role === 'DRIVER'
                  ? 'blue'
                  : 'deep-purple'
              "
              dark
              class="ma-1 px-1"
              small
              label
            >
              {{ role }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "Users",
  components: { ViewContainer, Toolbar },
  props: {
    type: [String, Array],
    editRoute: {
      type: String,
      default() {
        return "CUSTOMER";
      },
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 70 },
        { text: this.$t("user.username"), value: "username" },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("user.email"), value: "email" },
        { text: this.$t("general.phone"), value: "phone" },
        { text: this.$t("user.roles"), value: "roles", align: "right" },
        // { text: "Actions", value: "actions", align: "right", width: 250 }
      ],
    };
  },
  mounted() {
    // setInterval(() => {
    //   this.loadOnlineUsers();
    // }, 10000);
    this.loading = true;
    this.clearSelectedUser();
    this.loadUsers().then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("users", ["getUsers", "getUsersByProp", "getOnlineUsers"]),
    ...mapState({
      selected(state) {
        return state.users.selected;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
    getUsersOfTypes() {
      if (!this.type) {
        return this.getUsers;
      } else {
        return this.getUsersByProp("typeName", this.type);
      }
    },
  },
  methods: {
    ...mapActions("users", [
      "loadUsers",
      "deleteUser",
      "setSelectedUser",
      "clearSelectedUser",
      "loadOnlineUsers",
      "forceLogout",
      "startTrack"
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]), //, "setBreadcrumbs"

    // refreshBreadcrumb() {
    //   const updatedBreadcrumbs = this.$route.meta.breadcrumbs.map((item) => {
    //     return item;
    //   });
    //   this.setBreadcrumbs(updatedBreadcrumbs);
    // },
    selectionChanged(event) {
      if (event.value) {
        this.setSelectedUser(event.item);
      } else if(event.id && this.selected?.id !== event.id) {
        this.setSelectedUser(event);
      } else {
        this.clearSelectedUser();
      }
    },
    editUser() {
      if (this.selected) {
        this.$$router.push({
          name: this.editRoute,
          params: { userId: this.selected.id, back: { name: this.$route.name } },
          props: {
            type: this.selected.typeName,
          },
        });
      }
    },
    editRoles() {
      if (this.selected) {
        this.$$router.push({
          name: "admin.user.roles",
          params: { userId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    editStores() {
      if (this.selected) {
        this.$$router.push({
          name: "admin.user.stores",
          params: { userId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    selectStore() {

    },
    confirmDelete() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} user?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteUser(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    userInitials(name) {
      if (name && name.length > 0) {
        return `mdi-alpha-${name.toLowerCase()[0]}-circle-outline`;
      }
      return "mdi-account-circle";
    },
    isOnline(uuid) {
      return true;
      // return !!this.getOnlineUsers[uuid];
    },
  },
};
</script>
